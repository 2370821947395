<template>
  <div>
    <CCard color="white" text-color="black" class="text-center" body-wrapper>
      <div class="row">
        <div class="col-md-10 text-left">
          <h2 class="font-weight-normal">{{ data.title }}</h2>
        </div>
        <div class="col-md-2 text-right">
          <a @click="$router.go(-1)">
            <CButton>
              <CIcon name="cil-arrow-thick-from-right" />
              {{ $t('back') }}
            </CButton>
          </a>
        </div>
      </div>
      <hr />
      <CRow>
        <CCol md="4" class="text-left">
          <CCardBody border-color="greyborder">
            <h4 class="text-success">
              {{ dateRange(data.startAt, data.endAt) }}
            </h4>
            <br />

            <h5 class="text-dark"><em class="fa fa-calendar-check-o text-dark"></em> {{ $t('couponPeriod') }}</h5>
          </CCardBody>
        </CCol>
        <CCol md="4" class="text-left">
          <CCardBody border-color="greyborder">
            <h4 class="text-primary">
              {{ data.value }} {{ $t('baht') }}
            </h4>
            <br />
            <h5 class="text-dark"><em class="fas fa-coins text-dark"></em> {{ $t('couponValue') }}</h5>
          </CCardBody>
        </CCol>
        <CCol md="4" class="text-left">
          <CCardBody border-color="greyborder">
            <h4 class="text-warning">
              {{ balance }}
            </h4>
            <br />
            <h5 class="text-dark"><em class="fa fa-wallet text-dark"></em> {{ $t('remainingCoupon') }}</h5>
          </CCardBody>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol md="12">
          <CCard border-color="greyborder">
            <CCardBody>
              <CRow>
                <CCol md="4">
                  <img alt="" class="img-fluid" :src="data.imgUrl" width="100%" @error="noImgUrl" />
                </CCol>
                <CCol md="8">
                  <h4 class="font-weight-normal text-left">
                    {{ data.title }}
                    <span class="text-danger">{{ expiredAt(data.endAt) }}</span>
                  </h4>
                  <p class="text-left text-description">
                    {{ data.description }}
                  </p>
                  <br />
                  <br />
                  <div v-if="isCondition === true">
                    <p class="text-left font-weight-normal text-grey">
                      {{ $t('couponUsageCondition') }}
                    </p>
                    <hr />
                    <p class="text-left text-description">
                      {{ $t('showCouponToStaff') }}<br />
                      {{ $t('specialRightsNote') }}
                      <br />
                      {{ $t('reservationRights') }}
                      <br />
                      <span v-if="limitation !== ''">
                        4. {{ $t('limit') }} {{ limitation }}{{ $t('couponPerMember') }}
                      </span>
                    </p>
                  </div>
                  <div v-else-if="isCondition === false && limitation !== ''">
                    <p class="text-left font-weight-normal text-grey">
                      {{ $t('couponUsageCondition') }}
                    </p>
                    <hr />
                    <p class="text-left text-description">
                      1. {{ $t('limit') }} {{ limitation }} {{ $t('couponPerMember') }}
                    </p>
                  </div>
                  <p class="text-left text-grey">
                    {{ $t('createdAt') }} : {{ createdAt }}
                  </p>

                  <CButton v-if="data.deleted_at != null" class="btn-danger btn-block" disabled>
                    <CIcon name="cil-trash"></CIcon> {{ $t('deleteCoupon') }}
                  </CButton>
                  <CButton v-else class="btn-block btn-outline-danger" @click="popupModal = true">
                    <CIcon name="cil-trash"></CIcon> {{ $t('deleteCoupon') }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <hr />

      <h3 class="text-left font-weight-normal text-dark">{{ $t('couponUsageDetail') }}</h3>
      <br />
      <CDataTable :items="items" :fields="fields" hover border>
        <template #isUsed="{ item }">
          <td>
            <CBadge :color="getBadge(item.isUsed)">
              <span class="font-weight-normal">{{ item.isUsed }}</span>
            </CBadge>
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CModal :show.sync="popupModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center col-md-12">
        <CCard color="white" text-color="dark" class="text-center col-md-12">
          <h4>{{ $t('deleteCouponConfirmation') }}</h4>
          <p>
            {{ $t('deleteCouponDialog') }}
          </p>
        </CCard>
      </CRow>

      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="danger" @click="deleteCoupon()">
              {{ $t('delete') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="danger" disabled>
              <CSpinner color="light" size="sm" />{{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="popupModal = false">
              {{ $t('closed') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from '@/services/crm'
import moment from 'moment'

export default {
  data() {
    return {
      data: {},
      isCondition: '',
      limitation: '',
      detail: [],
      popupModal: false,
      footer: '',
      loadingButton: true,
    }
  },
  computed: {
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    balance() {
      if (this.data.balance === '') {
        return this.$i18n.t('unlimited')
      } else {
        return this.data.balance + this.$i18n.t('coupon')
      }
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('couponReceivedDate'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'usedDate',
          label: this.$i18n.t('couponUsedDate'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        { key: 'member', label: this.$i18n.t('member.txtmember'), _classes: 'text-right text-dark font-weight-normal' },
        { key: 'redemptionCode', label: this.$i18n.t('couponCode'), _classes: 'text-right text-dark font-weight-normal' },
        { key: 'isUsed', label: this.$i18n.t('couponStatus'), _classes: 'text-center text-dark font-weight-normal' },
        { key: 'expireAt', label: this.$i18n.t('expiryDate'), _classes: 'text-right text-dark font-weight-normal' },
      ]
    },
    items() {
      let detailCoupon = this.detail
      let info = []
      let isUsed = ''
      let exp = ''
      let member = '-'

      for (let data of detailCoupon) {
        if (data.isUsed === true) {
          isUsed = this.$i18n.t('used')
        } else if (data.isUsed === false) {
          isUsed = this.$i18n.t('unused')
        }

        if (data.expireAt === '') {
          exp = this.$i18n.t('noExpiryDate')
        } else {
          exp = moment(String(data.expireAt)).format('DD/MM/YYYY HH:mm:ss')
        }

        if (data.member !== undefined) {
          member = data.member.name
        }

        info.push({
          date: moment(String(data.created_at)).isValid()
            ? moment(String(data.created_at)).format('DD/MM/YYYY HH:mm:ss')
            : '-',

          usedDate: moment(String(data.usedAt)).isValid()
            ? moment(String(data.usedAt)).format('DD/MM/YYYY HH:mm:ss')
            : '-',
          member: member,
          redemptionCode: data.redemptionCode,
          isUsed: isUsed,
          expireAt: exp,
        })
      }
      return info
    },
  },
  mounted() {
    this.getCoupon()
    this.getCouponRedemption()
  },
  methods: {
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    getBadge(isUsed) {
      switch (this.$i18n.t(isUsed)) {
        case this.$i18n.t('used'):
          return 'success'
        case this.$i18n.t('unused'):
          return 'danger'
        default:
          return 'primary'
      }
    },
    expiredAt(datetime) {
      const date = moment()
      if (date.isAfter(moment(datetime))) {
        return '--- ' + this.$i18n.t('expired')
      } else {
        return ''
      }
    },
    dateRange(startAt, endAt) {
      if (startAt === '' && endAt === '') {
        return this.$i18n.t('noLimitTime')
      } else if (startAt == null && endAt == null) {
        return this.$i18n.t('noLimitTime')
      } else {
        let start = moment(startAt).format('DD/MM/YYYY')
        let end = moment(endAt).format('DD/MM/YYYY')
        return start + ' ' + this.$i18n.t('to') + ' ' + end
      }
    },
    getCoupon() {
      const uid = this.uid
      let objectId = this.$route.params.objectId

      axios
        .get('/api/v1.0/' + uid + '/coupon/get/' + objectId)
        .then((res) => {
          this.data = res.data.data.documents
          this.isCondition = this.data.isCondition
          this.limitation = this.data.limitation
          console.log('data', this.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCouponRedemption() {
      const uid = this.uid
      let couponObjectId = this.$route.params.objectId

      axios
        .get(
          '/api/v1.0/' +
          uid +
          '/getredemptionbycouponobjectId/' +
          couponObjectId
        )
        .then((res) => {
          this.detail = res.data.data.documents
        })
    },
    deleteCoupon() {
      this.loadingButton = false
      const uid = this.uid
      let objectId = this.$route.params.objectId

      axios
        .post('/api/v1.0/' + uid + '/coupon/delete/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            this.loadingButton = false
            this.$router.go(-1)
          } else {
            //มีบางอย่างผิดพลาด
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
